var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('b-row',[_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('CCard',[_c('CCardHeader',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('strong',[_vm._v("App Update Setting")])])])]),_c('CCardBody',[_c('div',{staticClass:"row"},[_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('ValidationObserver',{ref:"form1",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.register(_vm.update))},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('div',[_c('p',{staticClass:"m-0 mb-2",staticStyle:{"font-weight":"bold"}},[_vm._v(" Maintance Mode ")]),_c('b-form-checkbox',{attrs:{"size":"md","name":"check-button","switch":""},model:{value:(_vm.update.maintance),callback:function ($$v) {_vm.$set(_vm.update, "maintance", $$v)},expression:"update.maintance"}},[(_vm.update.maintance)?_c('span',[_vm._v("ON")]):_c('span',[_vm._v("OF")])])],1),_c('div',[_c('p',{staticClass:"m-0 mb-2",staticStyle:{"font-weight":"bold"}},[_vm._v(" Withdraw On Of ")]),_c('b-form-checkbox',{attrs:{"size":"md","name":"check-button","switch":""},model:{value:(_vm.update.task_on_off),callback:function ($$v) {_vm.$set(_vm.update, "task_on_off", $$v)},expression:"update.task_on_off"}},[(_vm.update.task_on_off)?_c('span',[_vm._v("ON")]):_c('span',[_vm._v("OF")])])],1),_c('div',{staticClass:"row"},[_c('b-col',{attrs:{"lg":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Version","vid":"version","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Current App Version ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"version","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.update.version),callback:function ($$v) {_vm.$set(_vm.update, "version", $$v)},expression:"update.version"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"lg":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Version","vid":"dlink","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" App Download link ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"dlink","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.update.dlink),callback:function ($$v) {_vm.$set(_vm.update, "dlink", $$v)},expression:"update.dlink"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1)],1),_c('div',{staticClass:"text-right"},[_c('b-button',{staticClass:"mr-2",staticStyle:{"margin-top":"40px"},attrs:{"type":"submit","variant":"primary"}},[_vm._v("Update")])],1)])]}}])})],1)],1)])],1)],1),_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('CCard',[_c('CCardHeader',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('strong',[_vm._v("App Setting's")])])])]),_c('CCardBody',[_c('div',{staticClass:"row"},[_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('ValidationObserver',{ref:"form2",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.register(_vm.setting))},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('b-col',{attrs:{"lg":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Main Notice Box","vid":"notice","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Main Notice Box ")]},proxy:true}],null,true)},[_c('b-form-textarea',{attrs:{"type":"number","id":"notice","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.setting.notice),callback:function ($$v) {_vm.$set(_vm.setting, "notice", $$v)},expression:"setting.notice"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"lg":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Point Conv Notice Box","vid":"convert_notice","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Point Conv Notice Box ")]},proxy:true}],null,true)},[_c('b-form-textarea',{attrs:{"type":"number","id":"convert_notice","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.setting.convert_notice),callback:function ($$v) {_vm.$set(_vm.setting, "convert_notice", $$v)},expression:"setting.convert_notice"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"lg":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Payment Notice Box","vid":"payment_notich","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Payment Notice Box ")]},proxy:true}],null,true)},[_c('b-form-textarea',{attrs:{"type":"number","id":"payment_notich","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.setting.payment_notich),callback:function ($$v) {_vm.$set(_vm.setting, "payment_notich", $$v)},expression:"setting.payment_notich"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1)],1),_c('div',{staticClass:"text-right"},[_c('b-button',{staticClass:"mr-2",staticStyle:{"margin-top":"40px"},attrs:{"type":"submit","variant":"primary"}},[_vm._v("Update")])],1)])]}}])})],1)],1)])],1)],1),_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('CCard',[_c('CCardHeader',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('strong',[_vm._v("App Ads Unit Setting's")])])])]),_c('CCardBody',[_c('div',{staticClass:"row"},[_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('ValidationObserver',{ref:"form3",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.register(_vm.addUnit))},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('b-col',{attrs:{"lg":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Strapps Apps ID","vid":"strapps","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Strapps Apps ID ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"strapps","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.addUnit.strapps),callback:function ($$v) {_vm.$set(_vm.addUnit, "strapps", $$v)},expression:"addUnit.strapps"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"lg":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Unity game ID","vid":"unity","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Unity game ID ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"unity","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.addUnit.unity),callback:function ($$v) {_vm.$set(_vm.addUnit, "unity", $$v)},expression:"addUnit.unity"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1)],1),_c('div',{staticClass:"text-right"},[_c('b-button',{staticClass:"mr-2",staticStyle:{"margin-top":"40px"},attrs:{"type":"submit","variant":"primary"}},[_vm._v("Update")])],1)])]}}])})],1)],1)])],1)],1),_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('CCard',[_c('CCardHeader',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('strong',[_vm._v(" App link Setting's")])])])]),_c('CCardBody',[_c('div',{staticClass:"row"},[_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('ValidationObserver',{ref:"form4",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.register(_vm.link))},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('b-col',{attrs:{"lg":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"How Work link","vid":"howwork","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" How Work link ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"howwork","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.link.howwork),callback:function ($$v) {_vm.$set(_vm.link, "howwork", $$v)},expression:"link.howwork"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"lg":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Telegram Group Link","vid":"telegram","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Telegram Group Link ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"telegram","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.link.telegram),callback:function ($$v) {_vm.$set(_vm.link, "telegram", $$v)},expression:"link.telegram"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"lg":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Facebook Page Link","vid":"fb_Link","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Facebook Page Link ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"fb_Link","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.link.fb_Link),callback:function ($$v) {_vm.$set(_vm.link, "fb_Link", $$v)},expression:"link.fb_Link"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Privacy Policy Link","vid":"privacy_policy_link","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"privacy_policy_link"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Privacy Policy Link "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"privacy_policy_link","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.link.privacy_policy_link),callback:function ($$v) {_vm.$set(_vm.link, "privacy_policy_link", $$v)},expression:"link.privacy_policy_link"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Terms Condition link","vid":"terms_condition_link","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"terms_condition_link"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Terms Condition link "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"terms_condition_link","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.link.terms_condition_link),callback:function ($$v) {_vm.$set(_vm.link, "terms_condition_link", $$v)},expression:"link.terms_condition_link"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"More Apps Link","vid":"more_apps_link","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"more_apps_link"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" More Apps Link "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"more_apps_link","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.link.more_apps_link),callback:function ($$v) {_vm.$set(_vm.link, "more_apps_link", $$v)},expression:"link.more_apps_link"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Rate Us link","vid":"rate_us_link","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"rate_us_link"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Rate Us link "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"rate_us_link","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.link.rate_us_link),callback:function ($$v) {_vm.$set(_vm.link, "rate_us_link", $$v)},expression:"link.rate_us_link"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1)],1),_c('div',{staticClass:"text-right"},[_c('b-button',{staticClass:"mr-2",staticStyle:{"margin-top":"40px"},attrs:{"type":"submit","variant":"primary"}},[_vm._v("Update")])],1)])]}}])})],1)],1)])],1)],1),_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('CCard',[_c('CCardHeader',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('strong',[_vm._v("Apps About Setting")])])])]),_c('CCardBody',[_c('div',{staticClass:"row"},[_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('ValidationObserver',{ref:"form5",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.register(_vm.about))},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('ValidationProvider',{attrs:{"name":"About Us","vid":"aboutus","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" About Us ")]},proxy:true}],null,true)},[_c('b-form-textarea',{attrs:{"id":"aboutus","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.about.aboutus),callback:function ($$v) {_vm.$set(_vm.about, "aboutus", $$v)},expression:"about.aboutus"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1)],1),_c('div',{staticClass:"text-right"},[_c('b-button',{staticClass:"mr-2",staticStyle:{"margin-top":"40px"},attrs:{"type":"submit","variant":"primary"}},[_vm._v("Update")])],1)])]}}])})],1)],1)])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }